import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import './ButtonBack.styl';
import withLocation from '../../../providers/withLocation';

const ButtonBack = ({ isLink, link }) =>
    isLink ? (
        <Link to={link}>
            <div className='back-button'>
                <div>RETOUR</div>
                <div className='icon icon-back' />
            </div>
        </Link>
    ) : null;
// <button
//     className='back-button'
//     onClick={() => navigate(-1, { replace: true })}
// >
//     <span>RETOUR</span> <span className='icon icon-back' />
// </button>
ButtonBack.defaultProps = {
    isLink: false,
    link: null,
};

ButtonBack.propTypes = {
    isLink: PropTypes.bool,
    link: PropTypes.string,
};

export default withLocation(ButtonBack);
