import isEmail from 'validator/es/lib/isEmail';

const fields = () => ({
    email: {
        iconName: 'Mail',
        label: 'Adresse Mail',
        name: 'email',
        options: {
            validate: {
                format: (value) =>
                    isEmail(value) || "Le format du mail n'est pas valide",
            },
        },
        placeholder: 'ex : jeandupont@mail.com',
        type: 'email',
    },
    id: {
        iconName: 'id',
        label: 'Identifiant du magasin',
        name: 'id',
        placeholder: 'ex : 82443934',
        required: true,
    },
    password: {
        iconName: 'Password',
        label: 'Mot de passe',
        name: 'password',
        placeholder: 'ex : M0nM0t2P@sse',
        type: 'password',
    },
});

export default fields;
