const activeEnv = process.env.MODE || process.env.NODE_ENV || `development`;

// eslint-disable-next-line import/no-extraneous-dependencies
require(`dotenv`).config({
    path: `.env.${activeEnv}`,
});

module.exports = {
    backgroundColor: `#2d2d2d`,

    description: `Brico Dépot`,

    icon: `src/images/app/logo-512.png`,

    iconName: 'gatsby-icon',

    // manifest config
    langs: {
        all: ['fr', 'en'],
        default: {
            description: `Brico Dépot`,
            lang: `fr`,
            name: `Brico Dépot`,
            short_name: `Brico Dépot`,
        },
        others: [],
    },

    // SEO
    name: `Brico Dépot`,

    pathPrefix: `/?source=pwa`,

    siteUrl: process.env.GATSBY_SITE_URL || `http://localhost:8000`,

    themeColor: `#2d2d2d`,

    title: `Brico Dépot`,
    titleAlt: `Brico Dépot`,
};
