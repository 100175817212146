// import { Dialog } from '@reach/dialog';
import PropTypes from 'prop-types';
import React from 'react';

import { Helmet } from 'react-helmet-async';
import siteConfig from '../../../config/siteConfig';
import { isLoggedIn } from '../../services/auth';
import FormLogin from '../components/FormLogin';
import Header from './Header/Header';

const MainLayout = ({ children, hasNoHeader, transparent }) => {
    // const [showDialog, setShowDialog] = useState(false);
    const isBrowser = () => typeof window !== 'undefined';

    if (isBrowser()) {
        if (!isLoggedIn()) {
            return <FormLogin />;
        }
    }

    return (
        <div id='app'>
            <Helmet
                defer={false}
                htmlAttributes={{
                    lang: 'fr',
                }}
                title='Brico App'
            />
            <div id='content-wrap'>
                {/* <Dialog
                    isOpen={showDialog}
                    onDismiss={() => {
                        setShowDialog(false);
                    }}
                    aria-label='Warning about next steps'
                    style={{ zIndex: 999 }}
                >
                    {showDialog ? <div>Hello</div> : null}
                </Dialog> */}
                {hasNoHeader ? null : (
                    <Header
                        siteTitle={siteConfig.title}
                        transparent={transparent}
                    />
                )}
                <main>
                    <div id='content'>{children}</div>
                </main>
            </div>
        </div>
    );
};
MainLayout.defaultProps = {
    hasNoHeader: false,
    transparent: false,
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
    hasNoHeader: PropTypes.bool,
    transparent: PropTypes.bool,
};

export default MainLayout;
