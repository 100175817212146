/* eslint-disable no-return-assign */
// import firebase from 'gatsby-plugin-firebase';

const isBrowser = typeof window !== `undefined`;

const getUser = () =>
    window.localStorage.gatsbyUser
        ? JSON.parse(window.localStorage.gatsbyUser)
        : {};

const getStore = () =>
    window.localStorage.gatsbyStore
        ? JSON.parse(window.localStorage.gatsbyStore)
        : {};

// const getUser = () => {
//     firebase.auth().onAuthStateChanged(function (user) {
//         if (user) {
//             console.log('getUser -> user', user);
//             setUser(user)

//         } else {
//             return {};
//         }
//         // return user !== null ? user : {};
//     });
//     var user = firebase.auth().currentUser;
//     console.log('getUser -> userA', user);
//     // console.log('getUser -> userData', userData);
//     return user !== null ? user : {};
// };

const setUser = (user) =>
    (window.localStorage.gatsbyUser = JSON.stringify(user));

const setStore = (store) =>
    (window.localStorage.gatsbyStore = JSON.stringify(store));

export const handleLogin = async (user, id) => {
    if (!isBrowser) return false;

    setUser(user);
    setStore({ id });

    return user;
};

export const isLoggedIn = () => {
    if (!isBrowser) return false;

    const user = getUser();
    const store = getStore();

    return !!user.email && !!store.id;
};

export const getCurrentUser = () => isBrowser && getUser();

export const getCurrentStore = () => isBrowser && getStore();

export const logout = (callback) => {
    if (!isBrowser) return;

    console.log(`Ensuring the \`gatsbyUser\` property exists.`);
    setUser({});
    callback();
};
