// eslint-disable-next-line import/prefer-default-export
// export const storesList = [
//     {
//         id: 1787,
//         label: 'ALENCON',
//     },
//     {
//         id: 1925,
//         label: 'ANDILLY',
//     },
//     {
//         id: 1763,
//         label: 'BAILLEUL',
//     },
//     {
//         id: 1795,
//         label: 'BIGANOS',
//     },
//     {
//         id: 1919,
//         label: 'BRIVE',
//     },
//     {
//         id: 1718,
//         label: 'CARPIQUET',
//     },
//     {
//         id: 1900,
//         label: 'CASTRES',
//     },
//     {
//         id: 1716,
//         label: 'CHALEZEULE',
//     },
//     {
//         id: 1754,
//         label: 'CHALONS EN CHAMPAGNE',
//     },
//     {
//         id: 1781,
//         label: 'CHARTRES',
//     },
//     {
//         id: 1907,
//         label: 'COLMAR',
//     },
//     {
//         id: 1744,
//         label: 'DOUAI',
//     },
//     {
//         id: 1924,
//         label: 'EPINAL',
//     },
//     {
//         id: 1738,
//         label: 'FORBACH',
//     },
//     {
//         id: 1761,
//         label: 'GAILLAC',
//     },
//     {
//         id: 1914,
//         label: 'HAGUENAU',
//     },
//     {
//         id: 1740,
//         label: 'LE MANS',
//     },
//     {
//         id: 1708,
//         label: 'LIEVIN',
//     },
//     {
//         id: 1777,
//         label: 'MARSEILLE',
//     },
//     {
//         id: 1722,
//         label: 'METZ',
//     },
//     {
//         id: 1932,
//         label: 'MONTARGIS',
//     },
//     {
//         id: 1934,
//         label: 'MONTCEAU LES MINES',
//     },
//     {
//         id: 1727,
//         label: 'MONTELIMAR',
//     },
//     {
//         id: 1788,
//         label: 'MONTEREAU',
//     },
//     {
//         id: 1760,
//         label: 'MORLAIX',
//     },
//     {
//         id: 1922,
//         label: 'PAMIERS',
//     },
//     {
//         id: 1701,
//         label: 'REIMS',
//     },
//     {
//         id: 1917,
//         label: 'SAINTES',
//     },
//     {
//         id: 1792,
//         label: 'SARREBOURG',
//     },
//     {
//         id: 1940,
//         label: 'SISTERON',
//     },
//     {
//         id: 1703,
//         label: 'ST HERBLAIN',
//     },
//     {
//         id: 1929,
//         label: 'ST MALO',
//     },
//     {
//         id: 1776,
//         label: 'ST WITZ',
//     },
//     {
//         id: 1733,
//         label: 'TARBES',
//     },
//     {
//         id: 1736,
//         label: 'TOURLAVILLE',
//     },
//     {
//         id: 1933,
//         label: 'VAL DE MURIGNY',
//     },
//     {
//         id: 1743,
//         label: 'ARRAS',
//     },
//     {
//         id: 1711,
//         label: 'CHARLEVILLE',
//     },
//     {
//         id: 1704,
//         label: 'CLAIRA',
//     },
//     {
//         id: 1764,
//         label: 'NANCY',
//     },
//     {
//         id: 1920,
//         label: 'RODEZ',
//     },
//     {
//         id: 1901,
//         label: 'VILLEFRANCHE',
//     },
//     {
//         id: 1951,
//         label: 'ALBERTVILLE',
//     },
//     {
//         id: 1729,
//         label: 'AMIENS',
//     },
//     {
//         id: 1779,
//         label: 'ANGERS',
//     },
//     {
//         id: 1939,
//         label: 'ANGERS BEAUCOUZE',
//     },
//     {
//         id: 1912,
//         label: 'ANGOULEME',
//     },
//     {
//         id: 1904,
//         label: 'ANNEMASSE',
//     },
//     {
//         id: 1756,
//         label: 'ARTIGUES',
//     },
//     {
//         id: 1936,
//         label: 'AUCH',
//     },
//     {
//         id: 1709,
//         label: 'AULNOY',
//     },
//     {
//         id: 1789,
//         label: 'AUXERRE',
//     },
//     {
//         id: 1745,
//         label: 'AVIGNON',
//     },
//     {
//         id: 1720,
//         label: 'BEAUVAIS',
//     },
//     {
//         id: 1784,
//         label: 'BETHUNE',
//     },
//     {
//         id: 1902,
//         label: 'BEZIERS',
//     },
//     {
//         id: 1712,
//         label: 'BLOIS',
//     },
//     {
//         id: 1772,
//         label: 'BOULOGNE',
//     },
//     {
//         id: 1705,
//         label: 'BOURGES',
//     },
//     {
//         id: 1748,
//         label: 'BREST',
//     },
//     {
//         id: 1762,
//         label: 'BRUAY',
//     },
//     {
//         id: 1734,
//         label: 'CAMBRAI',
//     },
//     {
//         id: 1915,
//         label: 'CARCASSONNE',
//     },
//     {
//         id: 1713,
//         label: 'CARSIX',
//     },
//     {
//         id: 1913,
//         label: 'CESSON',
//     },
//     {
//         id: 1774,
//         label: 'CHATEAUROUX',
//     },
//     {
//         id: 1714,
//         label: 'CHENOVE',
//     },
//     {
//         id: 1725,
//         label: 'CHOLET',
//     },
//     {
//         id: 1943,
//         label: 'DAX',
//     },
//     {
//         id: 1746,
//         label: 'DIEPPE',
//     },
//     {
//         id: 1717,
//         label: 'DISSAY',
//     },
//     {
//         id: 1941,
//         label: 'DREUX',
//     },
//     {
//         id: 1782,
//         label: 'FACHES',
//     },
//     {
//         id: 1786,
//         label: 'FLEURY MEROGIS',
//     },
//     {
//         id: 1751,
//         label: 'GARGES',
//     },
//     {
//         id: 1719,
//         label: 'LA RICAMARIE',
//     },
//     {
//         id: 1758,
//         label: 'LA ROCHE',
//     },
//     {
//         id: 1949,
//         label: 'LAVAL',
//     },
//     {
//         id: 1769,
//         label: 'LEERS',
//     },
//     {
//         id: 1723,
//         label: 'LEMPDES',
//     },
//     {
//         id: 1946,
//         label: 'LES ESSARTS',
//     },
//     {
//         id: 1950,
//         label: 'LONGWY',
//     },
//     {
//         id: 1739,
//         label: 'LORIENT',
//     },
//     {
//         id: 1737,
//         label: 'MACON',
//     },
//     {
//         id: 1778,
//         label: 'MARTIGUES',
//     },
//     {
//         id: 1908,
//         label: 'MAUBEUGE',
//     },
//     {
//         id: 1790,
//         label: 'MELUN',
//     },
//     {
//         id: 1797,
//         label: 'MEONS',
//     },
//     {
//         id: 1747,
//         label: 'MONTAUBAN',
//     },
//     {
//         id: 1791,
//         label: 'MONTBELIARD',
//     },
//     {
//         id: 1753,
//         label: 'MONTLUCON',
//     },
//     {
//         id: 1785,
//         label: 'MONTPELLIER',
//     },
//     {
//         id: 1935,
//         label: 'MOULINS',
//     },
//     {
//         id: 1765,
//         label: 'MULHOUSE',
//     },
//     {
//         id: 1938,
//         label: 'NANTERRE',
//     },
//     {
//         id: 1706,
//         label: 'NEVERS',
//     },
//     {
//         id: 1944,
//         label: 'NICE',
//     },
//     {
//         id: 1767,
//         label: 'NIMES',
//     },
//     {
//         id: 1759,
//         label: 'PAU',
//     },
//     {
//         id: 1757,
//         label: 'PERIGUEUX',
//     },
//     {
//         id: 1796,
//         label: 'PETITE FORET',
//     },
//     {
//         id: 1771,
//         label: 'QUIMPER',
//     },
//     {
//         id: 1947,
//         label: 'RENNES ST GREGOIRE',
//     },
//     {
//         id: 1783,
//         label: 'ROANNE',
//     },
//     {
//         id: 1750,
//         label: 'ROUEN RIBOUDET',
//     },
//     {
//         id: 1752,
//         label: 'SARAN',
//     },
//     {
//         id: 1956,
//         label: 'SARTROUVILLE',
//     },
//     {
//         id: 1952,
//         label: 'SAUMUR',
//     },
//     {
//         id: 1775,
//         label: 'ST BRIEUC',
//     },
//     {
//         id: 1927,
//         label: 'ST DIZIER',
//     },
//     {
//         id: 1911,
//         label: 'ST PRIEST',
//     },
//     {
//         id: 1942,
//         label: 'ST QUENTIN',
//     },
//     {
//         id: 1903,
//         label: 'STRASBOURG',
//     },
//     {
//         id: 1909,
//         label: 'THOUROTTE',
//     },
//     {
//         id: 1773,
//         label: 'TOULON',
//     },
//     {
//         id: 1755,
//         label: 'TOURCOING',
//     },
//     {
//         id: 1770,
//         label: 'TOURS',
//     },
//     {
//         id: 1742,
//         label: 'TROYES',
//     },
//     {
//         id: 1906,
//         label: 'VAIRES SUR MARNE',
//     },
//     {
//         id: 1768,
//         label: 'VALENCE',
//     },
//     {
//         id: 1937,
//         label: 'VILLETANEUSE',
//     },
//     {
//         id: 1953,
//         label: 'BALLAINVILLIERS',
//     },
//     {
//         id: 1954,
//         label: 'PONTAULT',
//     },
// ];

// eslint-disable-next-line import/prefer-default-export
export const storesList = [
    1111,
    1787,
    1925,
    1763,
    1795,
    1919,
    1718,
    1900,
    1716,
    1754,
    1781,
    1907,
    1744,
    1924,
    1738,
    1761,
    1914,
    1740,
    1708,
    1777,
    1722,
    1932,
    1934,
    1727,
    1788,
    1760,
    1922,
    1701,
    1917,
    1792,
    1940,
    1703,
    1929,
    1776,
    1733,
    1736,
    1933,
    1743,
    1711,
    1704,
    1764,
    1920,
    1901,
    1951,
    1729,
    1779,
    1939,
    1912,
    1904,
    1756,
    1936,
    1709,
    1789,
    1745,
    1720,
    1784,
    1902,
    1712,
    1772,
    1705,
    1748,
    1762,
    1734,
    1915,
    1713,
    1913,
    1774,
    1714,
    1725,
    1943,
    1746,
    1717,
    1941,
    1782,
    1786,
    1751,
    1719,
    1758,
    1949,
    1769,
    1723,
    1946,
    1950,
    1739,
    1737,
    1778,
    1908,
    1790,
    1797,
    1747,
    1791,
    1753,
    1785,
    1935,
    1765,
    1938,
    1706,
    1944,
    1767,
    1759,
    1757,
    1796,
    1771,
    1947,
    1783,
    1750,
    1752,
    1956,
    1952,
    1775,
    1927,
    1911,
    1942,
    1903,
    1909,
    1773,
    1755,
    1770,
    1742,
    1906,
    1768,
    1937,
    1953,
    1954,
    2001,
];
