import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import fields from '../../../constants/fields';
import { handleLogin } from '../../../services/auth';
import useFirebase from '../../../services/firebase/useFirebase';
import InputBloc from '../InputBloc/index';
import { storesList } from '../../../constants/storesList';

function FormLogin() {
    // const { email, id, password } = fields();
    const { id } = fields();
    const firebase = useFirebase();
    const [formError, setFormError] = useState(null);
    const { errors, handleSubmit, register } = useForm();

    const onSubmit = ({ id: storeId }) => {
        if (!firebase) return;

        const email = 'brico@brico.com';
        const password = 'adminbrico@brico';

        const storeExists = storesList.includes(parseInt(storeId, 10));

        if (storeExists) {
            firebase
                .auth()
                // .signInWithEmailAndPassword(data.email, data.password)
                .signInWithEmailAndPassword(email, password)
                .then((resp) => {
                    handleLogin(resp.user, storeId).then(() => {
                        navigate('/?logged');
                    });
                })
                .catch((error) => {
                    if (error.code === 'auth/user-not-found') {
                        setFormError('Votre identifiant est incorrecte');
                    } else {
                        setFormError(
                            'Une erreur est survenue sur le serveur, veuillez réessayer plus tard',
                        );
                    }
                });
        } else {
            setFormError('Votre identifiant est incorrecte');
        }
    };

    return (
        <form
            name='FormLogin'
            onSubmit={handleSubmit(onSubmit)}
            style={{ margin: '10% auto', maxWidth: '500px' }}
        >
            {/* {[id, email, password].map((item) => ( */}
            {[id].map((item) => (
                <InputBloc
                    key={`form-${item.name}-field-${item.name}`}
                    {...item}
                    register={register}
                    errors={errors}
                />
            ))}
            <div className='form-error'>{formError && formError}</div>

            <input type='submit' className='button-text' value='Valider' />
        </form>
    );
}

FormLogin.propTypes = {};

export default FormLogin;
