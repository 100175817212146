/* eslint-disable no-underscore-dangle */
import cn from 'clsx';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import screenfull from 'screenfull';

import './Header.styl';
import withLocation from '../../../providers/withLocation';
import ButtonBack from '../../components/ButtonBack/index';
import HomeButton from '../../components/HomeButton';

const logo = require('../../../images/app/LOGO-BB.svg');

const Header = ({ location, transparent }) => {
    const [isFs, setIsFs] = useState(false);

    const fullscreen = () => {
        if (typeof window !== 'undefined' && screenfull.isEnabled) {
            const getFullScreenNode = () =>
                document.documentElement || document.body;
            screenfull.toggle(getFullScreenNode());
        } else {
            console.log('no no no');
        }
    };

    if (typeof window !== 'undefined' && screenfull.isEnabled) {
        screenfull.on('change', () => {
            setIsFs(screenfull.isFullscreen);
        });
    }

    return (
        <header className={cn({ transparent })}>
            <div id='app-version'>v {process.env.__VERSION__}</div>
            <Link to='/'>
                <div className='logo'>
                    <img src={logo} alt='' />
                </div>
            </Link>
            {location.pathname !== '/grid' ? (
                <Link to='/grid' className='grid-button'>
                    <div className='icon icon-grid_view' />
                    <span>Voir tous les modèles</span>
                </Link>
            ) : (
                <ButtonBack />
            )}
            <HomeButton />
            {isFs ? null : (
                <button
                    className='fullscreen'
                    onClick={fullscreen}
                    type='button'
                >
                    <i className='icon icon-fullscreen' />
                </button>
            )}
        </header>
    );
};

Header.propTypes = {
    location: PropTypes.object.isRequired,
    transparent: PropTypes.bool.isRequired,
};

export default withLocation(Header);
